<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import Button from '@/Components/Button/Button.vue';
import Form from './Partials/Form.vue';
import LocationLayout from '@/Layouts/LocationLayout.vue';
import saveIcon from '@i/save-white.svg';

const route = inject('route');
const dayjs = inject('dayjs');

const props = defineProps({
    shift: Object,
    directApprovalConditionsOptions: Object,
    positionOptions: Object,
    dresscodeOptions: Object,
    shiftCountsByDate: Object,
});

const form = useForm({
    start_date: dayjs(props.shift.start).format('YYYY-MM-DD'),
    start_time: dayjs(props.shift.start).format('H:mm'),
    end_time: dayjs(props.shift.end).format('H:mm'),
    break: '1800',
    position_id: '',
    description: '',
    number_of_shifts: 1,
    travel_reimbursement: false,
    visible_to_freelancers: false,
    freelancer_hourly_wage: 1400,

    direct_approval_conditions: [],
    dresscode: '',
    ...props.shift,
    additional_wage: props.shift.additional_wage ?? 0,
});

const sendForm = () => {
    form.transform((data) => ({
        ...data,
        start: start.value.unix(),
        end: end.value.unix(),
    })).put(route('location.shifts.update', { shift: props.shift.id }), {
        // preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const date = computed(() => (form.start_date ? form.start_date : dayjs().format('YYYY-MM-DD')));
const dateAfterDay = computed(() =>
    form.start_date ? dayjs(form.start_date).add(1, 'day') : dayjs().add(1, 'day').format('YYYY-MM-DD')
);
const start = computed(() => dayjs(date.value + ' ' + form.start_time));
const end = computed(() => {
    let end = dayjs(date.value + ' ' + form.end_time);
    if (end.isBefore(start.value)) {
        end = dayjs(dateAfterDay.value + ' ' + form.end_time);
    }
    return end;
});
</script>

<template>
    <LocationLayout>
        <div class="text-blue">
            <h1 class="font-bold text-[32px] mb-2">{{ $t('Add shift') }}</h1>

            <FormKit type="form" id="ShiftForm" @submit="sendForm" :actions="false">
                <Form
                    :form="form"
                    :positionOptions="positionOptions"
                    :dresscodeOptions="dresscodeOptions"
                    :directApprovalConditionsOptions="directApprovalConditionsOptions"
                >
                    <div class="flex flex-col justify-end h-full">
                        <div class="flex justify-end">
                            <Button
                                orange
                                type="button"
                                :processing="form.processing"
                                :recentlySuccessful="form.recentlySuccessful"
                                :text="$t('Save')"
                                :icon="saveIcon"
                                @click="sendForm"
                            />
                        </div>
                    </div>
                </Form>
            </FormKit>
        </div>
    </LocationLayout>
</template>
